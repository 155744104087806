@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html {
    scroll-behavior: smooth;
}
body {
    color: #262D47;
    background: #FFFFFF;
    font-size: 15px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

.load {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    & > div {
        transform: scale(3);
    }
}

.svg__chart {
    margin-top: 35px;
    margin-bottom: 35px;
    width: 100%;
}

.font__circle {
    font-family: 'Circe', sans-serif;
}

.text-align-center {
    text-align: center;
}

.head {
    background-image: url("../src/assets/images/head_bg.webp");
    padding: 135px 35px 60px 35px;

    @media screen and (max-width: 768px) {
        padding: 135px 20px 60px 20px;
    }

    @media screen and (max-width: 480px) {
        background-image: none;
        background-color: #262d47;
    }
}

.faq__title {
    text-align: center;
    margin-bottom: 29px;
}

.head__wrapper {
    max-width: 1440px;
    display: flex;
    justify-content: space-between;
    gap: 35px;
    padding-bottom: 60px;
    box-sizing: border-box;
    margin: 0 auto;
}

.head__text {
    max-width: 528px;
    width: 100%;
    color: #FFFFFF;
}

.head__title {
    font-weight: 500;
    font-size: 41px;
    line-height: 57px;
    margin-bottom: 17px;
}

.head__desc {
    position: relative;
    width: 400px;
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 41px;
}

.head__button {
    max-width: fit-content;
    padding: 18px 32px;
    margin: 10px;
    border-radius: 61px;
    line-height: 18px;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    min-width: 213px !important;
    color: #FFFFFF;
    border: 1px solid #FFFFFF26;

    @media screen and (max-width: 480px) {
        margin: 0 auto;
    }
}

.head__button:hover {
    border: 1px solid white;
}

.background__red {
    background-color: #fb434b;
}

.background__blue {
    background-color: #3490FC;
}

.aml {
    font-family: 'Manrope', sans-serif;
    margin-top: 115px;
    max-width: 1150px;
    margin-left: auto;
    margin-right: auto;
}

.aml-footer {

    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1150px;
    margin: 80px auto 0;
    background: #3490FC26;
    padding: 37px 33px;
}

.footer__title {
    color: #3490FC;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.footer__text {
    margin-top: 15px;
    color: rgba(38, 45, 71, 0.6);
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.footer__text span {
    font-weight: 500;
    color: rgba(38, 45, 71, 1);
}

.quest__faq {
    padding-left: 20px;
}

.title {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 38px;
    line-height: 52px;
    color: #262D47;
    margin-left: 72px;
    margin-right: 72px;
}

.trial__title {
    color: #FFFFFF;
    max-width: 366px;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 38px;
    line-height: 52px;
}

.trial__text {
    position: relative;
    color: rgba(255, 255, 255, 0.8);
    max-width: 446px;
    margin: 18px auto;
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
}

.trial__text span {
    font-size: 31px;
    margin-left: 5px;
    margin-right: 5px;
    color: rgba(255, 255, 255, 1);
}

.text {
    margin-top: 18px;
    max-width: 932px;
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: rgba(38, 45, 71, 0.6);
    margin-left: 72px;
    margin-right: 72px;
}

.text__block {
    max-width: 1052px;
    margin-right: auto;
    margin-left: auto;
    color: #262D47;
    text-align: center;
    font-family: 'Manrope', sans-serif;
    font-size: 38px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.text__block span {
    color: #3490FC;
    font-weight: 600;
    margin-left: 5px;
    margin-right: 5px;
}

.slider {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    overflow: hidden;
    margin: 0 auto;
}

.slides {
    display: flex;
    justify-content: center;
    transition: transform 0.3s ease-in-out;
}

.slide {
    text-align: center;
    padding: 35px 28px 47px 28px;
    width: 271px;
    margin-right: 20px;
    border: 1px solid #EFF2FC;
    border-radius: 6px;
}

.slide__title {
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: #262D47;
}

.slide__text span {
    font-weight: 600;
    color: #3490FC;
}

.prev, .next {
    display: none;
}

.earn__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.earn__title {
    margin-left: 0;
    max-width: 472px;
}

.earn__text {
    margin-left: 0;
    max-width: 408px;
    margin-bottom: 30px;
}

.circles__container {
    display: grid;
    grid-template-columns: repeat(auto-fit, 350px);
    justify-content: center;
    grid-gap: 50px;
}

.circle__block {
    flex-shrink: 0;
    width: 350px;
    height: 350px;
    text-align: center;
    border: 1px solid #EEE;
    border-radius: 50%;
    padding: 120px 15px;
}

.circle__block__blue {
    flex-shrink: 0;
    width: 350px;
    height: 350px;
    text-align: center;
    border: 0;
    border-radius: 50%;
    padding: 90px 15px 120px 15px;
    background: #3490FC;
    color: #FFFFFF;
}

.circle__block__title {
    color: #3490FC;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.circle__block__title__white {
    font-size: 55px;
    color: #FFFFFF;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.circle__block__text {
    color: rgba(38, 45, 71, 0.6);
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
}

.circle__block__text__white {
    margin: 0 15px;
    color: #FFFFFF;
    opacity: 0.8;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
}

.trial__container {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.trial__container img {
    padding: 50px;
}

.emailSend__container {
    padding: 40px 20px;
    box-sizing: border-box;
    text-align: center;
    max-width: 1766px;
    height: auto;
    background: #262D47;
    border-radius: 24px;
}

.emailSend__title {
    color: #FFFFFF;
    max-width: 588px;
    margin-left: auto;
    margin-right: auto;
    font-style: normal;
    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
}

.emailSend__input__container {
    max-width: 605px;
    margin: 32px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.emailSend__input__container > input {
    display: block;
    outline: none;
    box-shadow: none;
    border-radius: 32px;
    width: 100%;
    border: 0;
    padding-left: 35px;
    box-sizing: border-box;
    min-height: 52px;
    background: #FFF;
}

.emailSend__input__container input:focus {
    outline: none;
    box-shadow: none;
}

.FAQ__card__container {
    padding: 22px 33px;
    height: auto;
    border: 1px solid #EFF2FC;
    border-radius: 6px;
    margin-bottom: 8px;
}

.card__title__container {
    max-width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.display-none {
    display: none;
}

.btn__close {
    cursor: pointer;
    width: 34px;
    height: 34px;
    background-image: url('assets/images/icons/close.webp');
    background-size: cover;
    flex-shrink: 0;
}

.btn__open {
    cursor: pointer;
    width: 34px;
    height: 34px;
    background-image: url('assets/images/icons/open.webp');
    background-size: cover;
}

.slider__container {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (min-width: 1580px) {
    .head__text {
        margin-top: 40px;
    }
}

@media screen and (max-width: 1380px) {
    .card {
        max-width: 250px;
        height: auto;
    }
}

@media screen and (max-width: 1165px) {
    .card {
        max-width: 200px;
    }
}

@media screen and (max-width: 1200px) {
    .prev, .next {
        display: block;
        top: 50%;
        margin: 15px;
        border: 1px solid #EFF2FC;
        padding: 6px 12px;
        border-radius: 50%;
        cursor: pointer;
        z-index: 1;
    }

    .prev img, .next img {
        height: 15px;
        width: 15px;
    }
}

@media screen and (max-width: 1000px) {
    .head__title {
        font-size: 24px;
        line-height: 34px;
    }

    .head__desc {
        width: 100%;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 20px;
    }

    .banner__header {
        max-width: 400px;
        padding: 0;
    }

    .title {
        max-width: 100%;
        margin-left: 0;
        margin-right: 0;
    }

    .text {
        margin-left: 0;
        margin-right: 0;
    }

    .earn__container {
        padding-left: 20px;
        padding-right: 20px;
    }

    .earn__container img {
        width: 400px;
        height: 400px;
    }

    .earn__container__text {
        max-width: 350px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width: 767px) {
    .svg__chart {
        margin: 15px 0;
    }
    .second__banner {
        display: none;
    }

    .head__wrapper {
        flex-direction: column;
        text-align: center;
        align-items: center;
        padding-bottom: 0;
    }

    .head__desc {
        width: 100%;
        font-size: 12px;
    }

    .banner__header {
        display: none;
    }

    .aml {
        margin-top: 50px;
        flex-direction: column;
        text-align: center;
    }

    .earn__container {
        flex-direction: column-reverse;
    }

    .earn__container img {
        margin-bottom: 50px;
        width: 300px;
        height: 300px;
    }

    .video__container {
        height: 300px;
    }

    .video__button {
        position: static;
        padding-top: 90px;
    }

    .circles__container {
        display: block;
    }

    .circle__block, .circle__block__blue {
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 30px;
    }

    .text__block {
        font-size: 19px;
    }

    .FAQ__card__container {
        width: 85%;
        padding: 15px 15px;
        margin-left: auto;
        margin-right: auto;
        text-align: left;
    }

    .trial__container {
        display: block;
        padding: 30px;
    }

    .trial__container img {
        padding: 0;
        width: 300px;
        height: 380px;
        margin: 20px auto;
    }

    .trial {
        margin-top: 50px;
    }

    .head__checkboks {
        display: block;
        max-width: 300px;
    }

    .head__checkboks_text {
        border: 0;
        max-width: 100%;
        font-size: 13px;
        padding-top: 10px;
    }

    .aml {
        max-width: 90%;
    }

    .title {
        margin-right: 0;
        margin-left: 0;
    }

    .text {
        margin-right: 0;
        margin-left: 0;
    }

    .slide__title {
        font-size: 18px;
        line-height: 20px;
    }

    .card {
        max-width: 308px;
    }

    .earn__title {
        text-align: center;
        max-width: 100%;
    }

    .title {
        font-size: 30px;
        line-height: 40px;
    }

    .earn__text {
        max-width: 100%;
    }

    .aml-footer {
        display: block;
        text-align: center;
    }

    .quest__faq {
        padding: 0;
        margin-top: 20px;
    }

    .emailSend__input__container {
        flex-direction: column;
        align-items: center;
        gap: 10px;
        border-radius: 16px;
        margin-left: auto;
        margin-right: auto;
        min-height: 140px;
        background: transparent;
    }

    .emailSend__input__container input,
    .emailSend__input__container input:focus {
        width: 97%;
        margin: 5px;
        border: 1px solid #EFF2FC;
        background: #FFF;
        border-radius: 16px;
        padding: 5px 5px 5px 35px;
    }
}

@media screen and  (max-width: 700px) {
    .emailSend__input__container {
        margin: 0 20px;
    }
}

@media screen and  (max-width: 480px) {
    .title {
        font-size: 24px;
    }

    .text {
        font-size: 12px;
        line-height: 18px;
    }
}

#notification {
    display: none;
    padding: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    color: white;
    transition: all 0.3s;
}

#notification.success {
    background-color: #4CAF50;
}

#notification.error {
    background-color: #f44336;
}

#notification.warning {
    background-color: #ff9800;
}

@font-face {
    font-family: 'Circe';
    src: url('assets/fonts/Circe-Regular.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Manrope';
    src: url('assets/fonts/Manrope-Regular.ttf') format('truetype');
    font-display: swap;
}
